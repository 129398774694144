import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import { fetchUserData, addCourseToUser, addUserToCourseRoster } from "api/db";

const WebDevelopmentCourse = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    let userData = JSON.parse(sessionStorage.getItem("userData"));
    setData(userData);

    if (userData) {
      fetchUserData(userData.id).once("value", (snapshot) => {
        let fetchedData = snapshot.val();
        let uid = Object.keys(fetchedData)[0];
        addCourseToUser(uid, "wd2");
        addUserToCourseRoster(uid, "web-development/2");
      });
    }
  }, []);

  return (
    <>
      <main className="relative mx-auto max-w-screen-xl">
        <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 z-10">
          <div className="text-center">
            <div
              className="py-1 bg-yellow-800 hover:bg-yellow-700 items-center text-yellow-100 leading-none rounded-full flex inline-flex mb-2 cursor-pointer"
              role="alert"
            >
              <span className="flex rounded-full uppercase px-1 py-1 text-xs font-bold mr-2 ml-2">
                WEB DEVELOPMENT
              </span>
            </div>
          </div>
          <div className="text-center">
            <h2 className="text-4xl tracking-tight font-bold text-gray-900 ">
              {(data && data.username && data.username) ||
                (data && data.id && data.id)}
              , you are all set!
            </h2>
            <p className="text-xl text-gray-600 max-w-lg mx-auto">
              You should receive a confirmation email with more details within a
              few minutes. If you didn't receive a confirmation, please email
              team@enlight.nyc and we'll look into it! :)
            </p>
          </div>

          {/* <div className="mt-6 flex justify-center mb-2">
            <div>
              <div className="rounded-md shadow">
                <Link to="/projects">
                  <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                    Explore projects &#8250;
                  </a>
                </Link>
              </div>
            </div>
          </div> */}
        </main>
      </main>
    </>
  );
};

export default WebDevelopmentCourse;
